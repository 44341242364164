import { authConstant } from "./constants";
import toast from "react-hot-toast";
import axiosInstance from "../helpers/axios";

export const login = (user) => {
  return async (dispatch) => {
    const id = toast.loading("Loading...");
    try {
      dispatch({ type: authConstant.LOGIN_REQUEST });
      const res = await axiosInstance.post(`/auth/user/login`, {
        ...user,
      });

      if (res.status === 200) {
        const { token, user } = res.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        dispatch({
          type: authConstant.LOGIN_SUCCESS,
          payload: {
            token,
            user,
          },
        });
        toast.dismiss(id);
        toast.success("Login Success.");
      }
    } catch (error) {
      toast.dismiss(id);
      const { data } = error?.response;

      dispatch({
        type: authConstant.LOGIN_FAILURE,
        payload: { msg: data.msg, status: error.response.status },
      });
      toast.error(`${data?.msg}`, { duration: 1500 });
    }
  };
};

export const signUp = (user) => {
  return async (dispatch) => {
    const id = toast.loading("Loading...");
    try {
      dispatch({ type: authConstant.SIGNUP_REQUEST });
      const res = await axiosInstance.post(`/auth/user/signup`, user);
      if (res.status === 201) {
        const { user, token, msg } = res.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        dispatch({
          type: authConstant.SIGNUP_SUCCESS,
          payload: { user, token },
        });
        dispatch(isUserLoggedIn());
        toast.success(`${msg}`, { duration: 1500, id: id });
      }
    } catch (error) {
      const { data } = error?.response;
      dispatch({
        type: authConstant.SIGNUP_FAILURE,
        payload: { error: data.error },
      });
      toast.error(`${data?.msg}`, { duration: 1500, id: id });
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    if (token) {
      const user = JSON.parse(localStorage.getItem("user"));
      dispatch({
        type: authConstant.LOGIN_SUCCESS,
        payload: {
          token,
          user,
        },
      });
    } else {
      dispatch({
        type: authConstant.LOGIN_FAILURE,
        payload: { error: "Failed to login!" },
      });
    }
  };
};

export const signout = () => {
  return async (dispatch) => {
    const id = toast.loading("Loading...");
    dispatch({ type: authConstant.LOGOUT_REQUEST });
    const res = await axiosInstance.get(`/auth/user/signout`);

    if (res.status === 200) {
      localStorage.clear();
      dispatch({ type: authConstant.LOGOUT_SUCCESS });
      toast.dismiss(id);
    } else {
      dispatch({
        type: authConstant.LOGOUT_FAILURE,
        payload: { error: res.data.error },
      });
      toast.dismiss(id);
    }
  };
};

export const getUserByToken = (token) => {
  return async (dispatch) => {
    // const id = toast.loading("Loading...");
    try {
      dispatch({
        type: authConstant.GET_USER_REQUEST,
      });

      const res = await axiosInstance.post(`/auth/user/get-user-by-token`, {
        token: token,
      });

      if (res.status === 200) {
        const { user } = res.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        dispatch({
          type: authConstant.GET_USER_SUCCESS,
          payload: user,
        });
        dispatch(isUserLoggedIn());
        // toast.success("Success!", { duration: 1500, id: id });
      }
    } catch (error) {
      const { data } = error?.response;

      dispatch({
        type: authConstant.GET_USER_FAILURE,
        payload: { msg: data.msg, status: error.response.status },
      });
      // toast.error(`${data?.msg}`, { duration: 1500, id: id });
    }
  };
};

export const sendPasswordResetLink = (data) => {
  return async (dispatch) => {
    const id = toast.loading("Loading...");
    dispatch({ type: authConstant.PASS_RESET_EMAIL_REQUEST });

    try {
      const res = await axiosInstance.post(
        `/auth/user/reset-password/send-email`,
        data
      );

      if (res.status === 200) {
        dispatch({
          type: authConstant.PASS_RESET_EMAIL_SUCCESS,
          payload: res.data,
        });
        toast.success(`${res?.data?.msg}`, { duration: 1500, id: id });
      }
    } catch (error) {
      const { data } = error.response;
      dispatch({
        type: authConstant.PASS_RESET_EMAIL_FAILURE,
        payload: data,
      });
      toast.error(`${data?.msg}`, { duration: 1500, id: id });
    }
  };
};

export const postGalleryImg = (data) => {
  return async (dispatch) => {
    const id = toast.loading("Loading...");
    const token = localStorage.getItem("token");
    try {
      dispatch({ type: authConstant.GALLERY_POST_REQUEST });
      const res = await axiosInstance.post(`/auth/user/post-img`, data);

      if (res.status === 200) {
        const { msg } = res.data;
        dispatch({
          type: authConstant.GALLERY_POST_SUCCESS,
        });
        dispatch(getUserByToken(token));
        toast.success(`${msg}`, { duration: 1500, id: id });
      }
    } catch (error) {
      const { data } = error.response;
      dispatch({
        type: authConstant.GALLERY_POST_FAILURE,
      });
      toast.error(`${data?.msg}`, { duration: 1500, id: id });
    }
  };
};

export const updateUserLog = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: authConstant.UPDATE_LOG_REQUEST });

      const res = await axiosInstance.get(
        `/auth/user/update-log?songId=${data?.songId}`
      );

      if (res.status === 200) {
        dispatch({
          type: authConstant.UPDATE_LOG_SUCCESS,
        });
      }
    } catch (error) {
      dispatch({
        type: authConstant.UPDATE_LOG_FAILURE,
      });
    }
  };
};
