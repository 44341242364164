export const authConstant = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  SIGNUP_REQUEST: "SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  CODE_REQUEST: "CODE_REQUEST",
  CODE_SUCCESS: "CODE_SUCCESS",
  CODE_FAILURE: "CODE_FAILURE",
  VERIFY_REQUEST: "VERIFY_REQUEST",
  VERIFY_SUCCESS: "VERIFY_SUCCESS",
  VERIFY_FAILURE: "VERIFY_FAILURE",
  PICTURE_REQUEST: "PICTURE_REQUEST",
  PICTURE_SUCCESS: "PICTURE_SUCCESS",
  PICTURE_FAILURE: "PICTURE_FAILURE",
  NOTIFICATION_REQUEST: "NOTIFICATION_REQUEST",
  NOTIFICATION_SUCCESS: "NOTIFICATION_SUCCESS",
  NOTIFICATION_FAILURE: "NOTIFICATION_FAILURE",
  PROFILE_UPDATE_REQUEST: "PROFILE_UPDATE_REQUEST",
  PROFILE_UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
  PROFILE_UPDATE_FAILURE: "PROFILE_UPDATE_FAILURE",
  PASS_RESET_EMAIL_REQUEST: "PASS_RESET_EMAIL_REQUEST",
  PASS_RESET_EMAIL_SUCCESS: "PASS_RESET_EMAIL_SUCCESS",
  PASS_RESET_EMAIL_FAILURE: "PASS_RESET_EMAIL_FAILURE",
  PASS_UPDATE_REQUEST: "PASS_UPDATE_REQUEST",
  PASS_UPDATE_SUCCESS: "PASS_UPDATE_SUCCESS",
  PASS_UPDATE_FAILURE: "PASS_UPDATE_FAILURE",
  GALLERY_POST_REQUEST: "GALLERY_POST_REQUEST",
  GALLERY_POST_SUCCESS: "GALLERY_POST_SUCCESS",
  GALLERY_POST_FAILURE: "GALLERY_POST_FAILURE",
  UPDATE_LOG_REQUEST: "UPDATE_LOG_REQUEST",
  UPDATE_LOG_SUCCESS: "UPDATE_LOG_SUCCESS",
  UPDATE_LOG_FAILURE: "UPDATE_LOG_FAILURE",
};

export const subConstant = {
  SUB_PLAN_REQUEST: "SUB_PLAN_REQUEST",
  SUB_PLAN_SUCCESS: "SUB_PLAN_SUCCESS",
  SUB_PLAN_FAILURE: "SUB_PLAN_FAILURE",
  SUB_STRIPE_CREATE_SUCCESS: "SUB_STRIPE_CREATE_SUCCESS",
  SUB_STRIPE_CREATE_FAILURE: "SUB_STRIPE_CREATE_FAILURE",
  SUB_STRIPE_CREATE_REQUEST: "SUB_STRIPE_CREATE_REQUEST",
  SUB_CREATE_REQUEST: "SUB_CREATE_REQUEST",
  SUB_CREATE_SUCCESS: "SUB_CREATE_SUCCESS",
  SUB_CREATE_FAILURE: "SUB_CREATE_FAILURE",
};

export const songConstant = {
  SONG_UPLOAD_REQUEST: "SONG_UPLOAD_REQUEST",
  SONG_UPLOAD_SUCCESS: "SONG_UPLOAD_SUCCESS",
  SONG_UPLOAD_FAILURE: "SONG_UPLOAD_FAILURE",
  SONG_BY_ARTIST_REQUEST: "SONG_BY_ARTIST_REQUEST",
  SONG_BY_ARTIST_SUCCESS: "SONG_BY_ARTIST_SUCCESS",
  SONG_BY_ARTIST_FAILURE: "SONG_BY_ARTIST_FAILURE",
  SONG_BY_USER_REQUEST: "SONG_BY_USER_REQUEST",
  SONG_BY_USER_SUCCESS: "SONG_BY_USER_SUCCESS",
  SONG_BY_USER_FAILURE: "SONG_BY_USER_FAILURE",
  SONG_BY_PERMALINK_REQUEST: "SONG_BY_PERMALINK_REQUEST",
  SONG_BY_PERMALINK_SUCCESS: "SONG_BY_PERMALINK_SUCCESS",
  SONG_BY_PERMALINK_FAILURE: "SONG_BY_PERMALINK_FAILURE",
  SONG_RESET: "SONG_RESET",
  SONG_BY_GENRE_REQUEST: "SONG_BY_GENRE_REQUEST",
  SONG_BY_GENRE_SUCCESS: "SONG_BY_GENRE_SUCCESS",
  SONG_BY_GENRE_FAILURE: "SONG_BY_GENRE_FAILURE",
  FEATURED_ARTISTS_REQUEST: "FEATURED_ARTISTS_REQUEST",
  FEATURED_ARTISTS_SUCCESS: "FEATURED_ARTISTS_SUCCESS",
  FEATURED_ARTISTS_FAILURE: "FEATURED_ARTISTS_FAILURE",
  FEATURED_TRACKS_REQUEST: "FEATURED_TRACKS_REQUEST",
  FEATURED_TRACKS_SUCCESS: "FEATURED_TRACKS_SUCCESS",
  FEATURED_TRACKS_FAILURE: "FEATURED_TRACKS_FAILURE",
  HANDLE_LIKE_REQUEST: "HANDLE_LIKE_REQUEST",
  HANDLE_LIKE_SUCCESS: "HANDLE_LIKE_SUCCESS",
  HANDLE_LIKE_FAILURE: "HANDLE_LIKE_FAILURE",
  POST_COMMENT_REQUEST: "POST_COMMENT_REQUEST",
  POST_COMMENT_SUCCESS: "POST_COMMENT_SUCCESS",
  POST_COMMENT_FAILURE: "POST_COMMENT_FAILURE",
};

export const playlistConstant = {
  CREATE_PLAYLIST_REQUEST: "CREATE_PLAYLIST_REQUEST",
  CREATE_PLAYLIST_SUCCESS: "CREATE_PLAYLIST_SUCCESS",
  CREATE_PLAYLIST_FAILURE: "CREATE_PLAYLIST_FAILURE",
  GET_PLAYLIST_REQUEST: "GET_PLAYLIST_REQUEST",
  GET_PLAYLIST_SUCCESS: "GET_PLAYLIST_SUCCESS",
  GET_PLAYLIST_FAILURE: "GET_PLAYLIST_FAILURE",
  GET_PUBLIC_PLAYLIST_REQUEST: "GET_PUBLIC_PLAYLIST_REQUEST",
  GET_PUBLIC_PLAYLIST_SUCCESS: "GET_PUBLIC_PLAYLIST_SUCCESS",
  GET_PUBLIC_PLAYLIST_FAILURE: "GET_PUBLIC_PLAYLIST_FAILURE",
};

export const albumConstant = {
  CREATE_ALBUM_REQUEST: "CREATE_ALBUM_REQUEST",
  CREATE_ALBUM_SUCCESS: "CREATE_ALBUM_SUCCESS",
  CREATE_ALBUM_FAILURE: "CREATE_ALBUM_FAILURE",
  GET_ALBUM_REQUEST: "GET_ALBUM_REQUEST",
  GET_ALBUM_SUCCESS: "GET_ALBUM_SUCCESS",
  GET_ALBUM_FAILURE: "GET_ALBUM_FAILURE",
  GET_PUBLIC_ALBUM_REQUEST: "GET_PUBLIC_ALBUM_REQUEST",
  GET_PUBLIC_ALBUM_SUCCESS: "GET_PUBLIC_ALBUM_SUCCESS",
  GET_PUBLIC_ALBUM_FAILURE: "GET_PUBLIC_ALBUM_FAILURE",
};
