import React, { useEffect, useRef, useState } from "react";
import MusicSuggestion from "../component/home/MusicSuggestion";
import { linkArray } from "../assets/data/homeFooterLink";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturedArtists, getFeaturedTracks } from "../redux/actions";
import { dot_gif, loading_1 } from "../assets/images";
import { genreData } from "../assets/data/genre";
import LeftNav from "../component/home/LeftNav";
import Navbar from "../component/shared/Navbar";
import Footer from "../component/shared/Footer";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import axiosInstance from "../redux/helpers/axios";
import axios from "axios";
import { TextInputField1 } from "../component/form/InputField";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));

  const [deviceScreenType, setDeviceScreenType] = useState("none");
  const [fullPageWidth, setFullPageWidth] = useState(-1);
  const [navRatio, setNavRatio] = useState(0.2);
  const [show, setShow] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const [vibez, setVibez] = useState(true);
  const [selectedGenres, setSelectedGeneres] = useState([]);
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [recommendation, setRecommendation] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [step, setStep] = useState(1);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [prompt, setPrompt] = useState("");

  const handlePrompt = (e) => {
    const { name, value } = e.target;
    setPrompt(value);
    console.log(value);
  };

  useEffect(() => {
    let fullPageWidth = window.innerWidth;
    setFullPageWidth(fullPageWidth);

    const handleResize = () => {
      if (window) {
        const fullPageWidth = window?.innerWidth;

        if (fullPageWidth < 768) {
          setDeviceScreenType("sm");
          setNavRatio(0);
        } else if (fullPageWidth < 1024) {
          setDeviceScreenType("md");
          setNavRatio(0.08);
        } else {
          setDeviceScreenType("lg");
          setNavRatio(0.2);
        }
      }
    };

    // Call the function on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window?.innerWidth]);

  useEffect(() => {
    dispatch(getFeaturedArtists());
    dispatch(getFeaturedTracks(20));
  }, []);

  useEffect(() => {
    axiosInstance.get("/auth/user/vibez/get-status").then((res) => {
      setShow(res.data.status);
      setVibez(res.data.status);
    });
  }, []);

  useEffect(() => {
    if (!vibez) {
      axios
        .get(
          `https://vibez-ai-d8c83b3e539c.herokuapp.com/generate_playlist/${user._id}`
        )
        .then((res) => {
          setRecommendation(res.data.playlist);
        });
    }
  }, [vibez]);

  const handleVibezInfo = async () => {
    if (selectedArtists.length <= 0 || selectedGenres.length <= 0) {
      toast.error("Select at least one Artist/Genre");
    } else {
      setLoading(true);
      const data = {
        preferredArtists: selectedArtists,
        preferredGenres: selectedGenres,
        vibez: {
          consent: checked,
          remind: false,
        },
      };

      try {
        await axiosInstance.post("/auth/user/vibez/customize", data);
        const res = await axios.get(
          `https://vibez-ai-d8c83b3e539c.herokuapp.com/generate_playlist/${user._id}`
        );
        setRecommendation(res.data.playlist);
        setLoading(false);
        setShow(false);
        toast.success("Done", { duration: 1500 });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const song = useSelector((state) => state.song);

  const handleSearch = () => {
    setSearching(true);
    axios
      .get(
        `https://vibez-ai-d8c83b3e539c.herokuapp.com/search_songs?prompt=${prompt}`
      )
      .then((res) => {
        setSearching(false);
        setSearchResult(res.data.songs);
      });
  };

  console.log(searchResult);

  return (
    <div className="bg-dark  min-h-screen ">
      {fullPageWidth !== -1 && deviceScreenType !== "none" ? (
        <div className="min-h-screen relative ">
          {/* NAV */}
          <Navbar />

          <div className="flex gap-0 mt-2 relative">
            {/* FIRST BOX */}
            <div
              style={{ width: window.innerWidth * navRatio }}
              className="lg:block md:block sm:hidden relative px-2"
            >
              <LeftNav
                deviceScreenType={deviceScreenType}
                linkArray={linkArray}
                setSearchModal={setSearchModal}
              />
            </div>
            <div className="border-2 border-globalBg lg:block md:block sm:hidden"></div>
            {/* SECOND BOX */}
            <div
              style={{ width: window.innerWidth * (1 - navRatio) }}
              className="rounded-lg "
            >
              {song?.loading ? (
                <div className="flex justify-center items-center min-h-screen ">
                  <img src={dot_gif} className="h-[100px]" />
                </div>
              ) : (
                <>
                  <MusicSuggestion
                    featuredArtists={song?.featuredArtists}
                    featuredTracks={song?.featuredTracks}
                    genres={genreData}
                    deviceScreenType={deviceScreenType}
                    recommendation={recommendation}
                  />
                  <div className="bg-globalBg py-[2px] rounded-lg">
                    <Footer />
                  </div>
                </>
              )}
            </div>
          </div>

          <ReactModal
            isOpen={show}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              },
              content: {
                width: "90%",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                // backgroundColor: "#1A1A1A",
                backgroundColor: "#070033",
                borderRadius: 8,
                border: "none",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                maxWidth: 840,
              },
            }}
            // onRequestClose={() => {
            //   setShow(false);
            // }}
          >
            {step === 1 && (
              <div className=" px-4 py-2 text-white">
                <div className="flex items-center gap-2">
                  <h2 className="text-[20px]">Introducing VIBEZ </h2>{" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="tw-text-mvnGreen-800"
                  >
                    <path
                      d="M6.9723 11.7117C6.79869 12.2534 6.36988 12.6822 5.82812 12.8558C6.36988 13.0294 6.79869 13.4583 6.9723 14C7.1459 13.4583 7.57454 13.0294 8.1163 12.8558C7.57454 12.6822 7.1459 12.2536 6.9723 11.7117Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M10.0919 0C9.70048 2.17825 7.97869 3.9002 5.80078 4.29181C7.97869 4.68326 9.70048 6.40538 10.0919 8.58346C10.4834 6.40521 12.2052 4.68326 14.3831 4.29181C12.2052 3.9002 10.4834 2.17825 10.0919 0Z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M2.87347 5.94067C2.60653 7.05502 1.72762 7.93409 0.613281 8.20103C1.72762 8.46797 2.6067 9.34704 2.87347 10.4614C3.14041 9.34704 4.01932 8.46797 5.13366 8.20103C4.01932 7.93409 3.14041 7.05502 2.87347 5.94067Z"
                      fill="currentColor"
                      class="tw-animate-pulse"
                    ></path>
                  </svg>
                </div>
                <div>
                  <p className="text-[12px] text-[#808080]">
                    Personalize your listening habit with our smart AI
                  </p>
                </div>

                <div className="mt-4">
                  <h2>Select genres you listen more often</h2>
                  <div className="flex gap-8 flex-wrap mt-4 justify-center">
                    {genreData.map((item) => (
                      <GenreCard
                        item={item}
                        selectedGenres={selectedGenres}
                        setSelectedGeneres={setSelectedGeneres}
                      />
                    ))}
                  </div>
                </div>

                <div className="flex justify-between mt-6">
                  <div />
                  <div className="flex gap-4">
                    <div
                      className="border border-1 py-1 px-4 rounded-full cursor-pointer flex items-center justify-center"
                      onClick={() => setShow(false)}
                    >
                      <h2>Remind Me Later</h2>
                    </div>
                    <div
                      onClick={() => setStep(2)}
                      className="bg-[#6813F2] py-1 px-4 rounded-full cursor-pointer flex items-center justify-center hover:scale-90 transition-all ease-in-out"
                    >
                      <h2>Next</h2>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {step === 2 && (
              <>
                {loading ? (
                  <div className="px-4 py-2 text-white">
                    <div className="flex items-center gap-2 ">
                      <h2 className="text-[20px]">Introducing VIBEZ </h2>{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-text-mvnGreen-800"
                      >
                        <path
                          d="M6.9723 11.7117C6.79869 12.2534 6.36988 12.6822 5.82812 12.8558C6.36988 13.0294 6.79869 13.4583 6.9723 14C7.1459 13.4583 7.57454 13.0294 8.1163 12.8558C7.57454 12.6822 7.1459 12.2536 6.9723 11.7117Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M10.0919 0C9.70048 2.17825 7.97869 3.9002 5.80078 4.29181C7.97869 4.68326 9.70048 6.40538 10.0919 8.58346C10.4834 6.40521 12.2052 4.68326 14.3831 4.29181C12.2052 3.9002 10.4834 2.17825 10.0919 0Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M2.87347 5.94067C2.60653 7.05502 1.72762 7.93409 0.613281 8.20103C1.72762 8.46797 2.6067 9.34704 2.87347 10.4614C3.14041 9.34704 4.01932 8.46797 5.13366 8.20103C4.01932 7.93409 3.14041 7.05502 2.87347 5.94067Z"
                          fill="currentColor"
                          class="tw-animate-pulse"
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <p className="text-[12px] text-[#808080]">
                        Personalize your listening habit with our smart AI
                      </p>
                    </div>

                    <div className="text-black flex justify-center items-center px-4 py-6 mx-auto">
                      <img
                        src={loading_1}
                        height={390}
                        width={390}
                        alt=""
                        className=" bg-primary px-1 rounded"
                      />
                    </div>

                    <div>
                      <h2 className="text-center">Please wait a moment...</h2>
                    </div>
                  </div>
                ) : (
                  <div className=" px-4 py-2 text-white">
                    <div className="flex items-center gap-2">
                      <h2 className="text-[20px]">Introducing VIBEZ </h2>{" "}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 15 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class="tw-text-mvnGreen-800"
                      >
                        <path
                          d="M6.9723 11.7117C6.79869 12.2534 6.36988 12.6822 5.82812 12.8558C6.36988 13.0294 6.79869 13.4583 6.9723 14C7.1459 13.4583 7.57454 13.0294 8.1163 12.8558C7.57454 12.6822 7.1459 12.2536 6.9723 11.7117Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M10.0919 0C9.70048 2.17825 7.97869 3.9002 5.80078 4.29181C7.97869 4.68326 9.70048 6.40538 10.0919 8.58346C10.4834 6.40521 12.2052 4.68326 14.3831 4.29181C12.2052 3.9002 10.4834 2.17825 10.0919 0Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M2.87347 5.94067C2.60653 7.05502 1.72762 7.93409 0.613281 8.20103C1.72762 8.46797 2.6067 9.34704 2.87347 10.4614C3.14041 9.34704 4.01932 8.46797 5.13366 8.20103C4.01932 7.93409 3.14041 7.05502 2.87347 5.94067Z"
                          fill="currentColor"
                          class="tw-animate-pulse"
                        ></path>
                      </svg>
                    </div>
                    <div>
                      <p className="text-[12px] text-[#808080]">
                        Personalize your listening habit with our smart AI
                      </p>
                    </div>

                    <div className="mt-4">
                      <h2>Select artists you like most</h2>
                      <div className="flex gap-8 flex-wrap mt-4 justify-center">
                        {song?.featuredArtists.map((item) => (
                          <ArtistCard
                            item={item}
                            selectedArtists={selectedArtists}
                            setSelectedArtists={setSelectedArtists}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="mt-6">
                      <label className="flex gap-2 items-center">
                        <input
                          type="checkbox"
                          class="accent-pink-500"
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                        />
                        <p className="text-[13px]">
                          I, provide my full consent to use my public data for
                          customizing my Upvibez profile with VIBEZ.
                        </p>
                      </label>
                    </div>

                    <div className="flex justify-between mt-6">
                      <div />
                      <div className="flex gap-4">
                        <div
                          className="border border-1 py-1 px-4 rounded-full cursor-pointer flex items-center justify-center"
                          onClick={() => setShow(false)}
                        >
                          <h2>Remind Me Later</h2>
                        </div>
                        <button
                          onClick={() => handleVibezInfo()}
                          disabled={!checked}
                          className="bg-[#6813F2] py-1 px-4 rounded-full cursor-pointer flex items-center justify-center hover:scale-90 transition-all ease-in-out"
                        >
                          <h2>Done</h2>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </ReactModal>

          <ReactModal
            isOpen={searchModal}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              },
              content: {
                width: "90%",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                // backgroundColor: "#1A1A1A",
                backgroundColor: "#070033",
                borderRadius: 8,
                border: "none",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                maxWidth: 640,
                minHeight: 440,
              },
            }}
            onRequestClose={() => {
              setSearchModal(false);
            }}
          >
            <div className=" px-4 py-2 text-white flex flex-col items-center">
              <div className="flex items-center gap-2">
                <h2 className="text-[20px]">Introducing VIBEZ Search </h2>{" "}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 15 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="tw-text-mvnGreen-800"
                >
                  <path
                    d="M6.9723 11.7117C6.79869 12.2534 6.36988 12.6822 5.82812 12.8558C6.36988 13.0294 6.79869 13.4583 6.9723 14C7.1459 13.4583 7.57454 13.0294 8.1163 12.8558C7.57454 12.6822 7.1459 12.2536 6.9723 11.7117Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M10.0919 0C9.70048 2.17825 7.97869 3.9002 5.80078 4.29181C7.97869 4.68326 9.70048 6.40538 10.0919 8.58346C10.4834 6.40521 12.2052 4.68326 14.3831 4.29181C12.2052 3.9002 10.4834 2.17825 10.0919 0Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M2.87347 5.94067C2.60653 7.05502 1.72762 7.93409 0.613281 8.20103C1.72762 8.46797 2.6067 9.34704 2.87347 10.4614C3.14041 9.34704 4.01932 8.46797 5.13366 8.20103C4.01932 7.93409 3.14041 7.05502 2.87347 5.94067Z"
                    fill="currentColor"
                    class="tw-animate-pulse"
                  ></path>
                </svg>
              </div>
              <div className="mt-5">
                <TextInputField1
                  value={prompt}
                  onChange={handlePrompt}
                  name={"prompt"}
                  width="400"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch(); // Call the search function
                    }
                  }}
                  placeholder={"Type a prompt..."}
                />
              </div>

              <div
                className="flex justify-center items-center bg-primary w-[100px] px-2 py-1 rounded-full text-black cursor-pointer"
                onClick={handleSearch}
              >
                <p>Search</p>
              </div>
              {!searching && searchResult?.length > 0 && (
                <div className="flex flex-col gap-2 mt-5">
                  {searchResult.map((song) => {
                    return (
                      <div
                        className="flex items-center gap-3 cursor-pointer"
                        onClick={() => navigate(`/track/${song?.permalink}`)}
                      >
                        <img
                          src={song?.img}
                          className="h-[60px] w-[60px] rounded"
                        />
                        <div className="flex flex-col gap-1">
                          <p className="lg:text-[13px] md:text-[11px] sm:text-[10px]">
                            {song?.title}
                          </p>
                          <p className="lg:text-[10px] md:text-[9px] sm:text-[9px]">
                            {song?.artist}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {searching && (
                <>
                  <div className="text-black flex justify-center items-center px-4 py-6 mx-auto">
                    <img
                      src={loading_1}
                      height={390}
                      width={390}
                      alt=""
                      className=" bg-primary px-1 rounded"
                    />
                  </div>

                  <div>
                    <h2 className="text-center">Please wait a moment...</h2>
                  </div>
                </>
              )}

              {!searching && searchResult.length <= 0 && (
                <div className="w-[75%] mt-10">
                  <p className="text-[13px] text-gray-500">
                    VIBEZ AI search works as your music curator. You can enter
                    any prompt e.g. How you are feeling, What kind of weather
                    you are in. And VIBEZ will recommend you music according to
                    your prompt. <br />
                    Enjoy!
                  </p>
                </div>
              )}
            </div>
          </ReactModal>
        </div>
      ) : (
        <>
          <div className="flex justify-center items-center mt-4">
            <img src={dot_gif} className="h-[100px]" />
          </div>
        </>
      )}
    </div>
  );
};

export default Home;

const GenreCard = ({ selectedGenres, setSelectedGeneres, item }) => {
  const handleGenreSelection = (g) => {
    const valid = selectedGenres.includes(g.name);
    if (valid) {
      // Remove the genre
      const updatedGenres = selectedGenres.filter((s) => s !== g.name);
      setSelectedGeneres(updatedGenres);
    } else {
      if (selectedGenres.length >= 5) {
        toast.error("You can select maximum 5 genres.", { duration: 1000 });
      } else {
        const updatedGenres = [...selectedGenres, g.name];
        setSelectedGeneres(updatedGenres);
      }
    }
  };

  return (
    <div
      style={{ width: 80 }}
      className="cursor-pointer"
      onClick={() => handleGenreSelection(item)}
    >
      <div
        style={{
          backgroundImage: `url(${item?.img})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: 80,
          width: 80,
        }}
        height={80}
        width={80}
        alt=""
        className={"rounded-xl"}
      >
        {selectedGenres.includes(item.name) && (
          <div
            style={{
              backgroundColor: "rgba(3, 80, 19, 0.5)",
            }}
            className="h-[80px] w-[80px] rounded-xl flex justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </div>
        )}
      </div>

      <h2 className="lg:text-[12px] md:text-[10px] sm:text-[10px] mt-1 text-center">
        {item?.name}
      </h2>
    </div>
  );
};

const ArtistCard = ({ selectedArtists, setSelectedArtists, item }) => {
  const handleArtistSelection = (g) => {
    const valid = selectedArtists.includes(g.name);
    if (valid) {
      // Remove the genre
      const updatedArtists = selectedArtists.filter((s) => s !== g.name);
      setSelectedArtists(updatedArtists);
    } else {
      if (selectedArtists.length >= 5) {
        toast.error("You can select maximum 5 artists.", { duration: 1000 });
      } else {
        const updatedArtists = [...selectedArtists, g.name];
        setSelectedArtists(updatedArtists);
      }
    }
  };

  return (
    <div
      style={{ width: 80 }}
      className="cursor-pointer"
      onClick={() => handleArtistSelection(item)}
    >
      <div
        style={{
          backgroundImage: `url(${item?.avatar.url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: 80,
          width: 80,
        }}
        height={80}
        width={80}
        alt=""
        className={"rounded-xl"}
      >
        {selectedArtists.includes(item.name) && (
          <div
            style={{
              backgroundColor: "rgba(3, 80, 19, 0.5)",
            }}
            className="h-[80px] w-[80px] rounded-xl flex justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
          </div>
        )}
      </div>

      <h2 className="lg:text-[12px] md:text-[10px] sm:text-[10px] mt-1 text-center">
        {item?.name}
      </h2>
    </div>
  );
};
